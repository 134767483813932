import React, { Component } from 'react';
import ContestDropdown from '../contest_dropdown/ContestDropdown';

import { withLocalize, Translate } from 'react-localize-redux';
import SweepstakesTranslation from '../../../../../translations/sweepstakes_rules.json';

import { getBaseName } from '../../../../../utilities/GlobalConsts';
import {Helmet} from 'react-helmet';

class Sweepstakes extends Component { 

    constructor(props) {
        super(props);

        this.props.addTranslation(SweepstakesTranslation);
    }

    render() {
        return (
            <div className="generic-wrapper">
                
                <Helmet>
                    <title>Sweepstakes Rules | Ctrl V - Virtual Reality Arcade</title>
                    <meta name="description" content="Rules and conditions for entering, participating in, and winning sweepstakes contests hosted by Ctrl V."/>
                    <link rel="canonical" href={`${getBaseName()}/sweepstakes/`} />
                    <meta property="og:title" content="Sweepstakes Rules | Ctrl V - Virtual Reality Arcade" />
                    <meta property="og:description" content="Rules and conditions for entering, participating in, and winning sweepstakes contests hosted by Ctrl V." />
                    <meta property="og:url" content={`${getBaseName()}/sweepstakes/`} />
                    <meta property="og:image" content="https://storage.googleapis.com/vault.api.ctrlv.ca/public/meta-images/aisle-meta.png" />
                    <meta name="twitter:description" content="Rules and conditions for entering, participating in, and winning sweepstakes contests hosted by Ctrl V." />
                    <meta name="twitter:title" content="Sweepstakes Rules | Ctrl V - Virtual Reality Arcade" />
                    <meta name="keywords" content="sweepstakes,rules,promotions" />
                </Helmet>

                {/* Hide this if sweepstakes are running */}
                {/* <Translate>
                    {({ translate }) => (
                        <ContestDropdown>
                            title={translate("no-contests")}
                        </ContestDropdown>
                    )}
                </Translate> */}
                

                {/* <Translate>
                    {({ translate }) => (
                        <ContestDropdown 
                            title={translate("beaverton-giveaway.title")}
                            body={(
                                <div className="generic-wrapper blue-sub-headings align-left">
                                    <div className="generic-sub-heading">
                                        <Translate id="beaverton-giveaway.section-1.title" />
                                    </div>
                                    <div className="generic-section">
                                        <Translate id="beaverton-giveaway.section-1.body-1" />
                                    </div>
                                    <div className="generic-section">
                                        <Translate id="beaverton-giveaway.section-1.body-2" />
                                    </div>

                                    <div className="generic-sub-heading">
                                        <Translate id="beaverton-giveaway.section-2.title" />
                                    </div>
                                    <div className="generic-section">
                                        <Translate id="beaverton-giveaway.section-2.body-1" />
                                    </div>

                                    <div className="generic-sub-heading">
                                        <Translate id="beaverton-giveaway.section-3.title" />
                                    </div>
                                    <div className="generic-section">
                                        <Translate id="beaverton-giveaway.section-3.body-1" />
                                    </div>

                                    <div className="generic-sub-heading">
                                        <Translate id="beaverton-giveaway.section-4.title" />
                                    </div>
                                    <div className="generic-section">
                                        <Translate id="beaverton-giveaway.section-4.body-1.pre-list-line" />
                                        <ul>
                                            <li><Translate id="beaverton-giveaway.section-4.body-1.list.item-1" /></li>
                                            <li><Translate id="beaverton-giveaway.section-4.body-1.list.item-2" /></li>
                                        </ul>
                                    </div>
                                    <div className="generic-section">
                                        <Translate id="beaverton-giveaway.section-4.body-2" />
                                    </div>

                                    <div className="generic-sub-heading">
                                        <Translate id="beaverton-giveaway.section-5.title" />
                                    </div>
                                    <div className="generic-section">
                                        <Translate id="beaverton-giveaway.section-5.body-1" />
                                    </div>
                                    <div className="generic-section">
                                        <Translate id="beaverton-giveaway.section-5.body-2" />
                                    </div>
                                    <div className="generic-section">
                                        <Translate id="beaverton-giveaway.section-5.body-3" />
                                    </div>

                                    <div className="generic-sub-heading">
                                        <Translate id="beaverton-giveaway.section-6.title" />
                                    </div>
                                    <div className="generic-section">
                                        <Translate id="beaverton-giveaway.section-6.body-1.pre-list-line" />
                                        <ul>
                                            <li><Translate id="beaverton-giveaway.section-6.body-1.list.item-1" /></li>
                                        </ul>
                                    </div>

                                    <div className="generic-sub-heading">
                                        <Translate id="beaverton-giveaway.section-7.title" />
                                    </div>
                                    <div className="generic-section">
                                        <Translate id="beaverton-giveaway.section-7.body-1" />
                                    </div>

                                    <div className="generic-sub-heading">
                                        <Translate id="beaverton-giveaway.section-8.title" />
                                    </div>
                                    <div className="generic-section">
                                        <Translate id="beaverton-giveaway.section-8.body-1" />
                                    </div>

                                    <div className="generic-sub-heading">
                                        <Translate id="beaverton-giveaway.section-9.title" />
                                    </div>
                                    <div className="generic-section">
                                        <Translate id="beaverton-giveaway.section-9.body-1" />
                                    </div>

                                    <div className="generic-sub-heading">
                                        <Translate id="beaverton-giveaway.section-10.title" />
                                    </div>
                                    <div className="generic-section">
                                        <Translate id="beaverton-giveaway.section-10.body-1" />
                                    </div>

                                </div>
                            )} 
                        />
                    )}
                </Translate> */}

                <Translate>
                    {({ translate }) => (
                        <ContestDropdown 
                            title={translate("waterloo-freeplay-sweepstakes.title")}
                            body={(
                                <div className="generic-wrapper blue-sub-headings align-left">
                                    <div className="generic-sub-heading">
                                        <Translate id="waterloo-freeplay-sweepstakes.section-1.title" />
                                    </div>
                                    <div className="generic-section">
                                        <Translate id="waterloo-freeplay-sweepstakes.section-1.body-1" />
                                    </div>
                                    <div className="generic-section">
                                        <Translate id="waterloo-freeplay-sweepstakes.section-1.body-2" />
                                    </div>

                                    <div className="generic-sub-heading">
                                        <Translate id="waterloo-freeplay-sweepstakes.section-2.title" />
                                    </div>
                                    <div className="generic-section">
                                        <Translate id="waterloo-freeplay-sweepstakes.section-2.body-1" />
                                    </div>

                                    <div className="generic-sub-heading">
                                        <Translate id="waterloo-freeplay-sweepstakes.section-3.title" />
                                    </div>
                                    <div className="generic-section">
                                        <Translate id="waterloo-freeplay-sweepstakes.section-3.body-1" />
                                    </div>

                                    <div className="generic-sub-heading">
                                        <Translate id="waterloo-freeplay-sweepstakes.section-4.title" />
                                    </div>
                                    <div className="generic-section">
                                        <Translate id="waterloo-freeplay-sweepstakes.section-4.body-1.pre-list-line" />
                                        <ul>
                                            <li><Translate id="waterloo-freeplay-sweepstakes.section-4.body-1.list.item-1" /></li>
                                            <li><Translate id="waterloo-freeplay-sweepstakes.section-4.body-1.list.item-2" /></li>
                                        </ul>
                                        <div className="generic-section">
                                        <Translate id="waterloo-freeplay-sweepstakes.section-4.body-2" />
                                        </div>
                                    </div>
                                    
                                    <div className="generic-sub-heading">
                                        <Translate id="waterloo-freeplay-sweepstakes.section-5.title" />
                                    </div>
                                    <div className="generic-section">
                                        <Translate id="waterloo-freeplay-sweepstakes.section-5.body-1" />
                                    </div>
                                    <div className="generic-section">
                                        <Translate id="waterloo-freeplay-sweepstakes.section-5.body-2" />
                                    </div>
                                    <div className="generic-section">
                                        <Translate id="waterloo-freeplay-sweepstakes.section-5.body-3" />
                                    </div>

                                    <div className="generic-sub-heading">
                                        <Translate id="waterloo-freeplay-sweepstakes.section-6.title" />
                                    </div>
                                    <div className="generic-section">
                                        <Translate id="waterloo-freeplay-sweepstakes.section-6.body-1.pre-list-line" />
                                        <ul>
                                            <li><Translate id="waterloo-freeplay-sweepstakes.section-6.body-1.list.item-1" /></li>
                                        </ul>
                                    </div>

                                    <div className="generic-sub-heading">
                                        <Translate id="waterloo-freeplay-sweepstakes.section-7.title" />
                                    </div>
                                    <div className="generic-section">
                                        <Translate id="waterloo-freeplay-sweepstakes.section-7.body-1" />
                                    </div>

                                    <div className="generic-sub-heading">
                                        <Translate id="waterloo-freeplay-sweepstakes.section-8.title" />
                                    </div>
                                    <div className="generic-section">
                                        <Translate id="waterloo-freeplay-sweepstakes.section-8.body-1" />
                                    </div>

                                    <div className="generic-sub-heading">
                                        <Translate id="waterloo-freeplay-sweepstakes.section-9.title" />
                                    </div>
                                    <div className="generic-section">
                                        <Translate id="waterloo-freeplay-sweepstakes.section-9.body-1" />
                                    </div>

                                    <div className="generic-sub-heading">
                                        <Translate id="waterloo-freeplay-sweepstakes.section-10.title" />
                                    </div>
                                    <div className="generic-section">
                                        <Translate id="waterloo-freeplay-sweepstakes.section-10.body-1" />
                                    </div>

                                </div>
                            )} 
                        />
                    )}
                </Translate>
            </div>
        );
    }
}

export default withLocalize(Sweepstakes);