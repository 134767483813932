import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { isBrowser } from 'react-device-detect';

import { getBaseName } from '../../../utilities/GlobalConsts';

import { withLocalize, Translate } from 'react-localize-redux';
import FAQTranslation from '../../../translations/faq.json';

import FaqSection from './faq_section/FaqSection';
import './Faq.css';
import {Helmet} from 'react-helmet';

class Faq extends Component {
    
    constructor(props) {
        super(props);

        this.state = {
            redirect: null
        }

        this.props.addTranslation(FAQTranslation);
    }

    render() {
        return (
            <div className={isBrowser ? "faq-wrapper faq-browser-wrapper" : "faq-wrapper"}>
                
                <Helmet>
                    <title>Frequently Asked Questions | Ctrl V - Virtual Reality Arcade</title>
                    <meta name="description" content="A collection of frequently asked questions about Ctrl V - pricing, how to book, guidelines, refund policy and more."/>
                    <link rel="canonical" href={`${getBaseName()}/faq/`} />
                    <meta property="og:title" content="Frequently Asked Questions | Ctrl V - Virtual Reality Arcade" />
                    <meta property="og:description" content="A collection of frequently asked questions about Ctrl V - pricing, how to book, guidelines, refund policy and more." />
                    <meta property="og:url" content={`${getBaseName()}/faq/`} />
                    <meta property="og:image" content="https://storage.googleapis.com/vault.api.ctrlv.ca/public/meta-images/aisle-meta.png" />
                    <meta name="twitter:description" content="A collection of frequently asked questions about Ctrl V - pricing, how to book, guidelines, refund policy and more." />
                    <meta name="twitter:title" content="Frequently Asked Questions | Ctrl V - Virtual Reality Arcade" />
                    <meta name="keywords" content="frequently,asked,questions,faq" />
                </Helmet>

                <div className="generic-wrapper">
                    <div className="generic-heading">
                        <Translate id="title" />
                    </div>
                </div>


                <div className="faq-heading">
                    <Translate id="bookings-procedures.title" />
                </div>
                    
                <span className="anchor" id="bookingq2"></span>
                <Translate>
                    {({ translate }) => (
                        <FaqSection 
                            title={translate("bookings-procedures.question-2.question")}
                            body={
                                <div>
                                    <Translate id="bookings-procedures.question-2.body.line-1" />
                                    <a href={`${getBaseName()}/buy-gift-cards/`}>
                                        <Translate id="bookings-procedures.question-2.body.link-text-1" />
                                    </a>
                                    <Translate id="bookings-procedures.question-2.body.line-2" />
                                </div>
                            } 
                        />
                    )}
                </Translate>
                    
                <span className="anchor" id="bookingq3"></span>
                <Translate>
                    {({ translate }) => (
                        <FaqSection 
                            title={translate("bookings-procedures.question-3.question")}
                            body={
                                <div>
                                    <Translate id="bookings-procedures.question-3.body.line-1" />
                                    <div className="faq-list">
                                        <ul>
                                            <li><Translate id="bookings-procedures.question-3.body.list.item-1" /></li>
                                            <li><Translate id="bookings-procedures.question-3.body.list.item-2" /></li>
                                            <li><Translate id="bookings-procedures.question-3.body.list.item-3" /></li>
                                        </ul>
                                    </div>
                                </div>
                            } 
                        />
                    )}
                </Translate>
                <span className="anchor" id="bookingq4"></span>
                <Translate>
                    {({ translate }) => (
                        <FaqSection 
                            title={translate("bookings-procedures.question-4.question")}
                            body={
                                <div>
                                    <Translate id="bookings-procedures.question-4.body" />
                                </div>
                            } 
                        />
                    )}
                </Translate>
                    

                <span className="anchor" id="bookingq5"></span>
                <Translate>
                    {({ translate }) => (
                        <FaqSection 
                            title={translate("bookings-procedures.question-5.question")}
                            body={
                                <div>
                                    <Translate id="bookings-procedures.question-5.body" />
                                </div>
                            } 
                        />
                    )}
                </Translate>
                    

                <span className="anchor" id="bookingq6"></span>
                <Translate>
                    {({ translate }) => (
                        <FaqSection 
                            title={translate("bookings-procedures.question-6.question")}
                            body={
                                <div>
                                    <Translate id="bookings-procedures.question-6.body" />
                                </div>
                            } 
                        />
                    )}
                </Translate>


                <span className="anchor" id="bookingq7"></span>
                <Translate>
                    {({ translate }) => (
                        <FaqSection 
                            title={translate("bookings-procedures.question-7.question")}
                            body={
                                <div>
                                <li><Translate id="bookings-procedures.question-7.body.list.item-1" /></li>
                                <li><Translate id="bookings-procedures.question-7.body.list.item-2" /></li>
                                    
                                </div>
                            } 
                        />
                    )}
                </Translate>

                <span className="anchor" id="bookingq8"></span>
                <Translate>
                    {({ translate }) => (
                        <FaqSection 
                            title={translate("bookings-procedures.question-8.question")}
                            body={
                                <div>
                                <li><Translate id="bookings-procedures.question-8.body.list.item-1" /></li>
                                <li><Translate id="bookings-procedures.question-8.body.list.item-2" /></li>
                                    
                                </div>
                            } 
                        />
                    )}
                </Translate>


                <span className="anchor" id="bookingq9"></span>
                <Translate>
                    {({ translate }) => (
                        <FaqSection 
                            title={translate("bookings-procedures.question-9.question")}
                            body={
                                <div>
                                <li><Translate id="bookings-procedures.question-9.body.list.item-1" /></li>
                                <li><Translate id="bookings-procedures.question-9.body.list.item-2" /></li>
                                    
                                </div>
                            } 
                        />
                    )}
                </Translate>

                <span className="anchor" id="bookingq10"></span>
                <Translate>
                    {({ translate }) => (
                        <FaqSection 
                            title={translate("bookings-procedures.question-10.question")}
                            body={
                                <div>
                                    <Translate id="bookings-procedures.question-10.body" />
                                </div>
                            } 
                        />
                    )}
                </Translate>

                <span className="anchor" id="bookingq11"></span>
                <Translate>
                    {({ translate }) => (
                        <FaqSection 
                            title={translate("bookings-procedures.question-11.question")}
                            body={
                                <div>
                                    <Translate id="bookings-procedures.question-11.body.before" />
                                    <a href={`${getBaseName()}/move-booking/`}>
                                        <Translate id="bookings-procedures.question-11.body.link-text" />
                                    </a>
                                    <Translate id="bookings-procedures.question-11.body.after" />

                                </div>
                            } 
                        />
                    )}
                </Translate>

                <span className="anchor" id="bookingq12"></span>
                <Translate>
                    {({ translate }) => (
                        <FaqSection 
                            title={translate("bookings-procedures.question-12.question")}
                            body={
                                <div>
                                    <Translate id="bookings-procedures.question-12.body" />
                                </div>
                            } 
                        />
                    )}
                </Translate>

                <span className="anchor" id="bookingq13"></span>
                <Translate>
                    {({ translate }) => (
                        <FaqSection 
                            title={translate("bookings-procedures.question-13.question")}
                            body={
                                <div>
                                    <Translate id="bookings-procedures.question-13.body" />
                                </div>
                            } 
                        />
                    )}
                </Translate>

                {/* <span className="anchor" id="bookingq14"></span>
                <Translate>
                    {({ translate }) => (
                        <FaqSection 
                            title={translate("bookings-procedures.question-14.question")}
                            body={
                                <div>
                                    <Translate id="bookings-procedures.question-14.body" />
                                </div>
                            } 
                        />
                    )}
                </Translate> */}

                <span className="anchor" id="bookingq15"></span>
                <Translate>
                    {({ translate }) => (
                        <FaqSection 
                            title={translate("bookings-procedures.question-15.question")}
                            body={
                                <div>
                                    <Translate id="bookings-procedures.question-15.body" />
                                </div>
                            } 
                        />
                    )}
                </Translate>

                <span className="anchor" id="bookingq16"></span>
                <Translate>
                    {({ translate }) => (
                        <FaqSection 
                            title={translate("bookings-procedures.question-16.question")}
                            body={
                                <div>
                                    <Translate id="bookings-procedures.question-16.body" />
                                </div>
                            } 
                        />
                    )}
                </Translate>

                <span className="anchor" id="bookingq17"></span>
                <Translate>
                    {({ translate }) => (
                        <FaqSection 
                            title={translate("bookings-procedures.question-17.question")}
                            body={
                                <div>
                                    <Translate id="bookings-procedures.question-17.body" />
                                </div>
                            } 
                        />
                    )}
                </Translate>

                
                <span className="anchor" id="bookingq18"></span>
                <Translate>
                    {({ translate }) => (
                        <FaqSection 
                            title={translate("bookings-procedures.question-18.question")}
                            body={
                                <div>
                                    <Translate id="bookings-procedures.question-18.body.line-1" />
                                </div>
                            } 
                        />
                    )}
                </Translate>

                <span className="anchor" id="bookingq19"></span>
                <Translate>
                    {({ translate }) => (
                        <FaqSection 
                            title={translate("bookings-procedures.question-19.question")}
                            body={
                                <div>
                                    <Translate id="bookings-procedures.question-19.body" />
                                </div>
                            } 
                        />
                    )}
                </Translate>

                <span className="anchor" id="bookingq20"></span>
                <Translate>
                    {({ translate }) => (
                        <FaqSection 
                            title={translate("bookings-procedures.question-20.question")}
                            body={
                                <div>
                                    <Translate id="bookings-procedures.question-20.body" />
                                </div>
                            } 
                        />
                    )}
                </Translate>

                <span className="anchor" id="bookingq21"></span>
                <Translate>
                    {({ translate }) => (
                        <FaqSection 
                            title={translate("bookings-procedures.question-21.question")}
                            body={
                                <div>
                                    <Translate id="bookings-procedures.question-21.body" />
                                </div>
                            } 
                        />
                    )}
                </Translate>

                <span className="anchor" id="bookingq22"></span>
                <Translate>
                    {({ translate }) => (
                        <FaqSection 
                            title={translate("bookings-procedures.question-22.question")}
                            body={
                                <div>
                                    <Translate id="bookings-procedures.question-22.body" />
                                </div>
                            } 
                        />
                    )}
                </Translate>

                <span className="anchor" id="bookingq23"></span>
                <Translate>
                    {({ translate }) => (
                        <FaqSection 
                            title={translate("bookings-procedures.question-23.question")}
                            body={
                                <div>
                                    <Translate id="bookings-procedures.question-23.body" />
                                </div>
                            } 
                        />
                    )}
                </Translate>

                <span className="anchor" id="bookingq24"></span>
                <Translate>
                    {({ translate }) => (
                        <FaqSection 
                            title={translate("bookings-procedures.question-24.question")}
                            body={
                                <div>
                                    <Translate id="bookings-procedures.question-24.body" />
                                </div>
                            } 
                        />
                    )}
                </Translate>

                <span className="anchor" id="bookingq25"></span>
                <Translate>
                    {({ translate }) => (
                        <FaqSection 
                            title={translate("bookings-procedures.question-25.question")}
                            body={
                                <div>
                                    <Translate id="bookings-procedures.question-25.body" />
                                </div>
                            } 
                        />
                    )}
                </Translate>

                <span className="anchor" id="bookingq26"></span>
                <Translate>
                    {({ translate }) => (
                        <FaqSection 
                            title={translate("bookings-procedures.question-26.question")}
                            body={
                                <div>
                                    <Translate id="bookings-procedures.question-26.body.before" />
                                    <a href={`${getBaseName()}/loyalty/`}>
                                        <Translate id="bookings-procedures.question-26.body.link-text" />
                                    </a>
                                    <Translate id="bookings-procedures.question-26.body.after" />

                                </div>
                            } 
                        />
                    )}
                </Translate>

                <span className="anchor" id="bookingq27"></span>
                <Translate>
                    {({ translate }) => (
                        <FaqSection 
                            title={translate("bookings-procedures.question-27.question")}
                            body={
                                <div>
                                    <Translate id="bookings-procedures.question-27.body" />
                                </div>
                            } 
                        />
                    )}
                </Translate>

                <span className="anchor" id="bookingq28"></span>
                <Translate>
                    {({ translate }) => (
                        <FaqSection 
                            title={translate("bookings-procedures.question-28.question")}
                            body={
                                <div>
                                    <Translate id="bookings-procedures.question-28.body" />
                                </div>
                            } 
                        />
                    )}
                </Translate>

                <div className="faq-heading">
                    <Translate id="games.title" />
                </div>

                <span className="anchor" id="gamesq1"></span>
                <Translate>
                    {({ translate }) => (
                        <FaqSection 
                            title={translate("games.question-1.question")}
                            body={
                                <div>
                                    <Translate id="games.question-1.body" />
                                </div>
                            } 
                        />
                    )}
                </Translate>

                <span className="anchor" id="gamesq2"></span>
                <Translate>
                    {({ translate }) => (
                        <FaqSection 
                            title={translate("games.question-2.question")}
                            body={
                                <div>
                                    <Translate id="games.question-2.body" />
                                </div>
                            } 
                        />
                    )}
                </Translate>

                <span className="anchor" id="gamesq3"></span>
                <Translate>
                    {({ translate }) => (
                        <FaqSection 
                            title={translate("games.question-3.question")}
                            body={
                                <div>
                                    <Translate id="games.question-3.body" />
                                </div>
                            } 
                        />
                    )}
                </Translate>

                <span className="anchor" id="gamesq4"></span>
                <Translate>
                    {({ translate }) => (
                        <FaqSection 
                            title={translate("games.question-4.question")}
                            body={
                                <div>
                                    <Translate id="games.question-4.body" />
                                </div>
                            } 
                        />
                    )}
                </Translate>
                

                <div className="faq-heading">
                    <Translate id="VR.title" />
                </div>

                <span className="anchor" id="vrq1"></span>
                <Translate>
                    {({ translate }) => (
                        <FaqSection 
                            title={translate("VR.question-1.question")}
                            body={
                                <div>
                                    <Translate id="VR.question-1.body" />
                                </div>
                            } 
                        />
                    )}
                </Translate>

                <span className="anchor" id="vrq2"></span>
                <Translate>
                    {({ translate }) => (
                        <FaqSection 
                            title={translate("VR.question-2.question")}
                            body={
                                <div>
                                    <Translate id="VR.question-2.body" />
                                </div>
                            } 
                        />
                    )}
                </Translate>

                <span className="anchor" id="vrq3"></span>
                <Translate>
                    {({ translate }) => (
                        <FaqSection 
                            title={translate("VR.question-3.question")}
                            body={
                                <div>
                                    <Translate id="VR.question-3.body" />
                                </div>
                            } 
                        />
                    )}
                </Translate>

                <div className="faq-heading">
                    <Translate id="facility-hardware.title" />
                </div>

                <span className="anchor" id="facility-hardwareq1"></span>
                <Translate>
                    {({ translate }) => (
                        <FaqSection 
                            title={translate("facility-hardware.question-1.question")}
                            body={
                                <div>
                                    <Translate id="facility-hardware.question-1.body.before-1" />
                                    <Link to="/locations">
                                        <Translate id="facility-hardware.question-1.body.link-text-1" />
                                    </Link>
                                    <Translate id="facility-hardware.question-1.body.before-2" />
                                    <Link to="/location-map">
                                        <Translate id="facility-hardware.question-1.body.link-text-2" />
                                    </Link>
                                </div>
                            } 
                        />
                    )}
                </Translate>

                <span className="anchor" id="facility-hardwareq2"></span>
                <Translate>
                    {({ translate }) => (
                        <FaqSection 
                            title={translate("facility-hardware.question-2.question")}
                            body={
                                <div>
                                    <Translate id="facility-hardware.question-2.body" />
                                </div>
                            } 
                        />
                    )}
                </Translate>

                <span className="anchor" id="facility-hardwareq3"></span>
                <Translate>
                    {({ translate }) => (
                        <FaqSection 
                            title={translate("facility-hardware.question-3.question")}
                            body={
                                <div>
                                    <Translate id="facility-hardware.question-3.body" />
                                </div>
                            } 
                        />
                    )}
                </Translate>

                <span className="anchor" id="facility-hardwareq4"></span>
                <Translate>
                    {({ translate }) => (
                        <FaqSection 
                            title={translate("facility-hardware.question-4.question")}
                            body={
                                <div>
                                    <Translate id="facility-hardware.question-4.body" />
                                    <a href="http://bit.ly/2rkKUZQ" target="_blank" rel="noopener noreferrer">http://bit.ly/2rkKUZQ</a>
                                </div>
                            } 
                        />
                    )}
                </Translate>
                
                <span className="anchor" id="facilityhardwareq5"></span>
                <Translate>
                    {({ translate }) => (
                        <FaqSection 
                            title={translate("facility-hardware.question-5.question")}
                            body={
                                <div>
                                    <Translate id="facility-hardware.question-5.body.before" />
                                    <a href="https://www.virtualrealityfranchise.com/" target="_blank" rel="noopener noreferrer"><Translate id="facility-hardware.question-5.body.link-text" /></a>
                                    {/* <Link to="/franchising">
                                        <Translate id="facility-hardware.question-5.body.link-text" />
                                    </Link> */}
                                    <Translate id="facility-hardware.question-5.body.after" />
                                </div>
                            } 
                        />
                    )}
                </Translate>

                <span className="anchor" id="facility-hardwareq6"></span>
                <Translate>
                    {({ translate }) => (
                        <FaqSection 
                            title={translate("facility-hardware.question-6.question")}
                            body={
                                <div>
                                    <Translate id="facility-hardware.question-6.body" />
                                </div>
                            } 
                        />
                    )}
                </Translate>
            </div>  
        );
    }
}

export default withLocalize(Faq);